export const photosUnitedStates = [
  {
    src: "https://live.staticflickr.com/65535/53200575393_38b204c2ac_o_d.jpg",
    width: 3,
    height: 2,
    title: "Plains - Iowa"
  },
  {
    src: "https://live.staticflickr.com/65535/53200159154_3821551fbf_o_d.jpg",
    width: 3,
    height: 2,
    title: "Backyard - Iowa"
  },
  {
    src: "https://live.staticflickr.com/65535/53200690080_b52b0aa958_o_d.jpg",
    width: 2,
    height: 3,
    title: "Mining Shack - Colorado"
  },
  {
    src: "https://live.staticflickr.com/65535/53200575473_52d40f6d40_o_d.jpg",
    width: 3,
    height: 2,
    title: "Light on the Hills - Colorado"
  },
  {
    src: "https://live.staticflickr.com/65535/53199889247_2bb39ce004_o_d.jpg",
    width: 3,
    height: 2,
    title: "Ridge - Colorado"
  },
  {
    src: "https://live.staticflickr.com/65535/53200353806_e2a8c56625_o_d.jpg",
    width: 3,
    height: 2,
    title: "Sun through Trees - Colorado"
  },
  {
    src: "https://live.staticflickr.com/65535/53200353891_e14788a4d3_o_d.jpg",
    width: 3,
    height: 2,
    title: "Road - Colorado"
  },
  {
    src: "https://live.staticflickr.com/65535/53200576023_a95f9f42aa_o_d.jpg",
    width: 3,
    height: 2,
    title: "Dead End - Colorado"
  },
  {
    src: "https://live.staticflickr.com/65535/53200159939_0fc9b5e34d_o_d.jpg",
    width: 3,
    height: 2,
    title: "Fog on Plains - Colorado"
  },
  {
    src: "https://live.staticflickr.com/65535/52009369476_fc3758b8ba_o_d.jpg",
    width: 2,
    height: 3,
    title: "Canteen - Ottumwa, IA"
  },
  {
    src: "https://live.staticflickr.com/65535/52008345547_8db27c0796_o_d.jpg",
    width: 3,
    height: 2,
    title: "Ottumwa Daily Courier - Ottumwa, IA"
  },
  {
    src: "https://live.staticflickr.com/65535/52009882805_62fb36879e_o_d.jpg",
    width: 2,
    height: 3,
    title: "Train Tracks - Agency, IA"
  },
  {
    src: "https://live.staticflickr.com/65535/52009882840_c4977f7cf4_o_d.jpg",
    width: 3,
    height: 2,
    title: "Sunset - Agency, IA"
  },
  {
    src: "https://live.staticflickr.com/65535/52009369626_63c407a86b_o_d.jpg",
    width: 3,
    height: 2,
    title: "Barn - Hedrick, IA"
  },
  {
    src: "https://live.staticflickr.com/65535/52008345727_c7910ccd39_o_d.jpg",
    width: 2,
    height: 3,
    title: "Smokey Joe's - Oskaloosa, IA"
  },
  {
    src: "https://live.staticflickr.com/65535/52009419788_29dbd3849c_o_d.jpg",
    width: 3,
    height: 2,
    title: "Liquor Store - Oskaloosa, IA"
  },
  {
    src: "https://live.staticflickr.com/65535/52009613679_47801b1c4b_o_d.jpg",
    width: 3,
    height: 2,
    title: "Sunshine Nursery Sign - Agency, IA"
  },
  {
    src: "https://live.staticflickr.com/65535/52008346237_a8d055a0ab_o_d.jpg",
    width: 3,
    height: 2,
    title: "Los Dos Compadres - Chicago, IL"
  },
];