import React, { useState } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faXmark} from "@fortawesome/free-solid-svg-icons";
import MainMenu from "./MainMenu";
import MobileMenu from "./MobileMenu";

const Header = ({ album, albumSetter }: any) => {
  const [isNavOpen, setIsNavOpen] = useState<boolean>(false);

  return (
    <header className={"flex items-baseline justify-between px-4 py-5 sticky top-0 bg-white/90"}>
      <h1 className={"logoName text-3xl font-bold"}>Matthew Davis</h1>
      <nav>
        <section className="flex lg:hidden">
          <FontAwesomeIcon
            icon={faBars as any}
            onClick={() => setIsNavOpen((prev) => !prev)}
          />
          <div className={isNavOpen ? "showMenuNav" : "hideMenuNav"}>
            <FontAwesomeIcon
              icon={faXmark as any}
              className="absolute top-0 right-0 px-8 py-8"
              onClick={() => setIsNavOpen(false)}
            />
            <MobileMenu album={album} albumSetter={albumSetter} menuSetter={setIsNavOpen} />
          </div>
        </section>
        <MainMenu album={album} albumSetter={albumSetter} />
      </nav>
    </header>
  )
}

export default Header;
