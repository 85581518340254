export const photosFrance = [
  {
    src: "https://live.staticflickr.com/65535/52088623525_3d991fb3e7_o_d.jpg",
    width: 3,
    height: 2,
    title: "State of Vehicles - Beziers"
  },
  {
    src: "https://live.staticflickr.com/65535/52088125416_c10633fc03_o_d.jpg",
    width: 2,
    height: 3,
    title: "Petit Paris - Beziers"
  },
  {
    src: "https://live.staticflickr.com/65535/52088370939_4a0da56b78_o_d.jpg",
    width: 3,
    height: 2,
    title: "Man out for a walk - Beziers"
  },
  {
    src: "https://live.staticflickr.com/65535/52088125521_599fac3ef3_o_d.jpg",
    width: 2,
    height: 3,
    title: "Worker - Beziers"
  },
  {
    src: "https://live.staticflickr.com/65535/52088623640_461a84df5f_o_d.jpg",
    width: 3,
    height: 2,
    title: "Couple talking - Beziers"
  },
  {
    src: "https://live.staticflickr.com/65535/52088125706_8b9b748d57_o_d.jpg",
    width: 3,
    height: 2,
    title: "Municipal theatre - Sete"
  },
  {
    src: "https://live.staticflickr.com/65535/52087095162_89b16d43b6_o_d.jpg",
    width: 2,
    height: 3,
    title: "Best Friends - Beziers"
  },
  {
    src: "https://live.staticflickr.com/65535/52088125961_9edc0429d1_o_d.jpg",
    width: 3,
    height: 2,
    title: "Smoker - Beziers"
  },
  {
    src: "https://live.staticflickr.com/65535/52088624010_9c7054cfe5_o_d.jpg",
    width: 3,
    height: 2,
    title: "Umbrellas - Beziers"
  },
];