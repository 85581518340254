export const photosMorocco = [
  {
    src: "https://live.staticflickr.com/65535/52357990348_1120bd8c09_o_d.jpg",
    width: 3,
    height: 2,
    title: "Rooftops - Marrakesh"
  },
  {
    src: "https://live.staticflickr.com/65535/52358175345_87b7c8e3d2_o_d.jpg",
    width: 3,
    height: 2,
    title: "Mountains - Trail to Toubkal Refuge"
  },
  {
    src: "https://live.staticflickr.com/65535/52357989853_c5381501fd_o_d.jpg",
    width: 3,
    height: 2,
    title: "Mountains - Toubkal Summit"
  },
  {
    src: "https://live.staticflickr.com/65535/52356801282_610d214e53_o_d.jpg",
    width: 3,
    height: 2,
    title: "Football - Aroumd"
  },
  {
    src: "https://live.staticflickr.com/65535/52356800827_f0d7feafbe_o_d.jpg",
    width: 3,
    height: 2,
    title: "Men Standing - Roadside"
  },
  {
    src: "https://live.staticflickr.com/65535/52358174205_911fa48f82_o_d.jpg",
    width: 3,
    height: 2,
    title: "Landscape - Roadside"
  },
  {
    src: "https://live.staticflickr.com/65535/52357750811_3515a8b98e_o_d.jpg",
    width: 3,
    height: 2,
    title: "Tower - Dades Gorge"
  },
  {
    src: "https://live.staticflickr.com/65535/52356800582_e10b8b3f16_o_d.jpg",
    width: 3,
    height: 2,
    title: "Boys Sitting - Sandstone Cliffs"
  },
  {
    src: "https://live.staticflickr.com/65535/52358173890_970324ff2e_o_d.jpg",
    width: 2,
    height: 3,
    title: "Man Walking - Todgha Gorge"
  },
  {
    src: "https://live.staticflickr.com/65535/52357988808_65d3446772_o_d.jpg",
    width: 3,
    height: 2,
    title: "Sky - Sahara"
  },
  {
    src: "https://live.staticflickr.com/65535/52356800322_16737f853a_o_d.jpg",
    width: 3,
    height: 2,
    title: "Sand Dunes - Sahara"
  },
  {
    src: "https://live.staticflickr.com/65535/52357750481_5ed83be629_o_d.jpg",
    width: 3,
    height: 2,
    title: "Man Resting - Sahara"
  },
  {
    src: "https://live.staticflickr.com/65535/52358173740_a44c0dc274_o_d.jpg",
    width: 3,
    height: 2,
    title: "Sand Dunes - Sahara"
  },
  {
    src: "https://live.staticflickr.com/65535/52356800247_505e037811_o_d.jpg",
      width: 2,
    height: 3,
    title: "Camel - Sahara"
  },
  {
    src: "https://live.staticflickr.com/65535/52358173755_e703542f72_o_d.jpg",
    width: 3,
    height: 2,
    title: "Cat - Marrakesh"
  },
];