import React, { useState } from 'react';
import { Footer, Header, Portfolio } from "./components";
import {Album} from "./types/types";

const App = () => {
  const [album, setAlbum] = useState<Album>(Album.SCOTLAND);

  return (
    <>
      <Header album={album} albumSetter={setAlbum} />
      <Portfolio album={album} />
      <Footer />
    </>
  );
};

export default App;
