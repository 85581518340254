import React from 'react';
import {Album} from "../../types/types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookF, faFlickr, faGithub, faStackOverflow} from "@fortawesome/free-brands-svg-icons";

const MainMenu = ({ album, albumSetter }: any) => {

  return (
    <div className={"flex items-center justify-between px-4 py-5 sticky top-0 hidden lg:flex"}>
      <ul className="hidden lg:flex mx-12">
        <li
            className={`px-4 cursor-pointer underline decoration-2 hover:decoration-sky-500 ${album === Album.SCOTLAND && "decoration-sky-500"}`}
            onClick={() => albumSetter(Album.SCOTLAND)}
        >
          Scotland
        </li>
        <li
            className={`px-4 cursor-pointer underline decoration-2 hover:decoration-sky-500 ${album === Album.UNITED_STATES && "decoration-sky-500"}`}
            onClick={() => albumSetter(Album.UNITED_STATES)}
        >
          United States
        </li>
        <li
            className={`px-4 cursor-pointer underline decoration-2 hover:decoration-sky-500 ${album === Album.FRANCE && "decoration-sky-500"}`}
            onClick={() => albumSetter(Album.FRANCE)}
        >
          France
        </li>
        <li
            className={`px-4 cursor-pointer underline decoration-2 hover:decoration-sky-500 ${album === Album.MOROCCO && "decoration-sky-500"}`}
            onClick={() => albumSetter(Album.MOROCCO)}
        >
          Morocco
        </li>
        <li
            className={`px-4 cursor-pointer underline decoration-2 hover:decoration-sky-500 ${album === Album.NORWAY && "decoration-sky-500"}`}
            onClick={() => albumSetter(Album.NORWAY)}
        >
          Norway
        </li>
        <li>
          <a className={"px-4 underline decoration-2 hover:decoration-sky-500"}
             href={"https://www.matthewdavis.uk"}>Blog</a>
        </li>
      </ul>
      <ul className="hidden lg:flex">
        <li className={"inline-block"}>
          <a href={"https://www.facebook.com/profile.php?id=100011778057718"} className={"px-2 hover:text-sky-500"}>
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
        </li>
        <li className={"inline-block"}>
          <a href={"https://github.com/matthew-davis"} className={"px-2 hover:text-sky-500"}>
            <FontAwesomeIcon icon={faGithub} />
          </a>
        </li>
        <li className={"inline-block"}>
          <a href={"https://stackoverflow.com/users/1621001/ashcanschool?tab=profile"} className={"px-2 hover:text-sky-500"}>
            <FontAwesomeIcon icon={faStackOverflow} />
          </a>
        </li>
        <li className={"inline-block"}>
          <a href={"https://www.flickr.com/photos/190583382@N07/"} className={"px-2 hover:text-sky-500"}>
            <FontAwesomeIcon icon={faFlickr} />
          </a>
        </li>
      </ul>
    </div>
  )
}

export default MainMenu;
