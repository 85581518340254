import React from 'react';
import {Album} from "../../types/types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookF, faFlickr, faGithub, faLinkedinIn, faStackOverflow} from "@fortawesome/free-brands-svg-icons";

const MobileMenu = ({ album, albumSetter, menuSetter}: any) => {

  return (
    <ul className="flex flex-col items-center justify-between min-h-[250px]">
      <li
        className={`my-6 px-4 cursor-pointer underline decoration-2 hover:decoration-sky-500 ${album === Album.SCOTLAND && "decoration-sky-500"}`}
        onClick={() => {
          albumSetter(Album.SCOTLAND);
          menuSetter(false);
        }}
      >
        Scotland
      </li>
      <li
        className={`my-6 px-4 cursor-pointer underline decoration-2 hover:decoration-sky-500 ${album === Album.UNITED_STATES && "decoration-sky-500"}`}
        onClick={() => {
          albumSetter(Album.UNITED_STATES);
          menuSetter(false);
        }}
      >
        United States
      </li>
      <li
        className={`my-6 px-4 cursor-pointer underline decoration-2 hover:decoration-sky-500 ${album === Album.FRANCE && "decoration-sky-500"}`}
        onClick={() => {
          albumSetter(Album.FRANCE);
          menuSetter(false);
        }}
      >
        France
      </li>
      <li className="my-6">
        <a className={"px-4 underline decoration-2 hover:decoration-sky-500"} href={"https://www.matthewdavis.uk"}>Blog</a>
      </li>
      <li className="my-6">
        <ul>
          <li className={"inline-block"}>
            <a href={"https://www.facebook.com/profile.php?id=100011778057718"} className={"px-2 hover:text-sky-500"}>
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
          </li>
          <li className={"inline-block"}>
            <a href={"https://www.linkedin.com/in/drmatthewdavis/"} className={"px-2 hover:text-sky-500"}>
              <FontAwesomeIcon icon={faLinkedinIn} />
            </a>
          </li>
          <li className={"inline-block"}>
            <a href={"https://github.com/matthew-davis"} className={"px-2 hover:text-sky-500"}>
              <FontAwesomeIcon icon={faGithub} />
            </a>
          </li>
          <li className={"inline-block"}>
            <a href={"https://stackoverflow.com/users/1621001/ashcanschool?tab=profile"} className={"px-2 hover:text-sky-500"}>
              <FontAwesomeIcon icon={faStackOverflow} />
            </a>
          </li>
          <li className={"inline-block"}>
            <a href={"https://www.flickr.com/photos/190583382@N07/"} className={"px-2 hover:text-sky-500"}>
              <FontAwesomeIcon icon={faFlickr} />
            </a>
          </li>
        </ul>
      </li>
    </ul>
  )
}

export default MobileMenu;
