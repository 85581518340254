import React from 'react';

const Footer = () => {

  return (
    <footer className={"mt-5 px-4 py-2 sticky b-0"}>
      <p>&copy; {new Date().getFullYear()} Matthew Davis - All rights reserved.</p>
    </footer>
  )
}

export default Footer;
