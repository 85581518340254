export const photosNorway = [
  {
    src: "https://live.staticflickr.com/65535/52689385369_de8ab92933_o_d.jpg",
    width: 2,
    height: 3,
    title: "Tram Driver - Oslo"
  },
  {
    src: "https://live.staticflickr.com/65535/52689385309_26b026fc65_o_d.jpg",
    width: 3,
    height: 2,
    title: "Operahuset - Oslo"
  },
  {
    src: "https://live.staticflickr.com/65535/52688588952_7c72cf4628_o_d.jpg",
    width: 3,
    height: 2,
    title: "Tram - Oslo"
  },
  {
    src: "https://live.staticflickr.com/65535/52689534345_5c3872aa92_o_d.jpg",
    width: 2,
    height: 3,
    title: "Shop - Tromsø"
  }
];
