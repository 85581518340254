import React, {useCallback, useState} from 'react';
import Gallery from "react-photo-gallery";
import Carousel, {Modal, ModalGateway, ViewType} from "react-images";
import { photosScotland } from "./photosScotland";
import { photosFrance } from "./photosFrance";
import { photosUnitedStates } from "./photosUnitedStates";
import { photosMorocco } from "./photosMorocco";
import { photosNorway } from "./photosNorway";
import {Album} from "../../types/types";

const Portfolio = ({ album = Album.SCOTLAND }) => {
  const [currentImage, setCurrentImage] = useState<number>(0);
  const [viewerIsOpen, setViewerIsOpen] = useState<boolean>(false);

  const openLightbox = useCallback((event: any, { index }: any) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const selectedAlbum = () => {
    if (album === Album.SCOTLAND) return photosScotland;
    if (album === Album.UNITED_STATES) return photosUnitedStates;
    if (album === Album.FRANCE) return photosFrance;
    if (album === Album.MOROCCO) return photosMorocco;
    if (album === Album.NORWAY) return photosNorway;
    return photosScotland;
  }

  return (
    <main role="main" className="pt-1 px-2">
      <Gallery photos={selectedAlbum()} onClick={openLightbox} />
      { /* @ts-ignore */ }
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={selectedAlbum().map(x => ({
                ...x,
                caption: x.title
              })) as unknown as ViewType[]}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </main>
  );
};

export default Portfolio;
